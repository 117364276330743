.scrollable {
    height: 75vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
}

.scrollable::-webkit-scrollbar {
    -webkit-appearance: none;
  }

.viewHeader{
    position: relative;
    padding-bottom: 10px;
    width: 100%;
    background-color: white
}