.speedo {
    min-height: 200px;
    height: 30vh;
    padding: 10px;
    position: relative;
    margin-top: 10px;
}

.gaugePercent {
    transform-box: fill-box;
  transform-origin: center;
  transform: rotate(130deg);
}

.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;

}

.name {
  position: absolute;
  font-size: 26px;
  color: #707070;
  text-align: left;
  font-weight: 200;
}

.container {
  display: inline-block;
  width: fit-content;
  position: relative;
}

.sensorInfo {
  color: #707070;
  font-size: 22px;
}

.infoValue {
  color: blue;
}

.sensorInfo > tbody > tr > td:first-child {
  text-align: left;
}
.sensorInfo > tbody > tr > td:last-child {
  text-align: right;
}


.sensorInfo > tbody > tr > td:first-child {
  width: 30%;
}

.radialGaugePointerKnob {
  transition: all ease-in-out 0.4s;
}

.sensorInfo > tbody > tr > td {
  padding-top: 8px;
  padding-bottom: 8px;
}