.loginWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginForm {
    background-color: #E9ECEF;
    padding: 40px;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loginForm > label {
    width: 100%;
    color: #707070;
    font-size: 22px;
}

.loginForm > label > input {
    width: 100%;
    border-radius: 4px;
    outline: none;
    border: none;
    height: 40px;
    padding: 8px;
    color: #707070;
}

.formSubmit {
    float: right;
    background-color: lightgray;
    outline: none;
    border: none;
    border-radius: 8px;
    width: 160px;
    height: 40px;
    color: #707070;
    margin-top: 20px;
    font-size: 24px;
}

.formSubmit:hover {
    color: black;
    background-color: rgb(141, 141, 141);
    transition: all ease-in-out 0.2s;
}

.error {
    color: red;
    background-color: lightgray;
    padding: 5px;
    font-size: 20px;
    border-radius: 8px;
}