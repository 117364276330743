.notification {
    border-radius: 4px;
    margin: 4px;
    padding: 4px 8px 4px 8px;
    vertical-align: middle;
    font-size: 14px;
    color: black;
    white-space: nowrap;
    font-weight: 600;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    font-weight: 400;
}

.notification.danger {
    background-color: lightcoral;
}

.notification.warning {
    background-color: lightsalmon;
}

.notification.info {
    background-color: lightblue;
}

.notification.success {
    background-color: lightgreen;
}

.notificationText.danger {
    color: red;
}

.notificationText.warning {
    color: orange;
}

.notificationText.info {
    color: blue;
}

.notificationText.success {
    color: green;
}