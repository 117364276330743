.siloGroup{
    white-space: nowrap;
    display: inline-flex;
    width: 100%;
    overflow-x: scroll;
}

.wrapper {
    height: 100%;
    overflow-y: scroll;
    padding-left: 10px;
}