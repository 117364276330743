.split-pane-right,
.split-pane-left {
  flex: 1;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

.fullsizeButtonWrapper {
    -webkit-user-select: none;  
    -moz-user-select: none; 
    -ms-user-select: none;  
    user-select: none; 
}
.fullsizeButton { 
  position: fixed;
    font-size: 20px;
    color: #707070;
    z-index: 10;
}

.noSelect {
  -webkit-user-select: none;  
  -moz-user-select: none; 
  -ms-user-select: none;  
  user-select: none; 
}