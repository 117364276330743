.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0;
    z-index: -100;
    transition: opacity ease-in-out 0.2;
}

.spinner {
    width: 80px;
    height: 80px;
    color: #707070;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.backdropPanel {
    position: relative;
    width: calc(100% - 20px);
    height: 100%;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0;
    z-index: -100;
    transition: opacity ease-in-out 0.2;
}

.spinnerPanel {
    width: 80px;
    height: 80px;
    color: #707070;
    position: absolute;
    left: calc(50% + 20px);
    top: 50%;
    transform: translate(-50%, -50%);
}

.active {
    opacity: 1;
    z-index: 200;
}

.backdropPanel.active {
    width: calc(100% - 20px);
    height: 100%;
}