table {
    width: 100%;
    font-size: 20px;
}

.tableDate {
    vertical-align: top;
    width: 15vw;
}

td {
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 400;
}

.icon {
    font-size: 15px;
    margin-left: 10px;
}

.cancelIcon {
    font-size: 30px;
    color: #707070;
    vertical-align: middle;
}

.cancelIcon:hover {
    color: black;
}

.danger {
    color: red
}

.warning {
    color: orange
}

.info {
    color: black
}

.success{
    color: green;
}

.commentForm textarea {
    height: 80px;
    width: 100%;
    border: 1px solid gray;
    border-radius: 4px;
    resize: none;
    box-sizing: border-box;
    padding: 10px;
}

.commentForm textarea:focus {
    border-color: black;
    outline: none;
}

.commentFormButton {
    display: inline-block;
    cursor: pointer;
    color: #707070;
    font-size: 20px;
    border: 1px solid #707070;
    background-color: transparent;
    border-radius: 4px;
    text-align: center;
    margin: 1vh 2% 0 0;
    font-weight: 700;
}

.commentFormButton:hover {
    color: black;
    border-color: black;
}