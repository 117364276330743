.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    align-items: center;
    width:100%;
    display: inline-block;
    /* padding: 1% 2%; */
    background-color: #E9ECEF;
    vertical-align: middle;
    z-index: 100;
}
.central {
    position: absolute;
    left: 250px;
    /* width: 40%; */
    height: 100%;
    z-index: 21;
}

@media screen and (max-width: 1100px) {
    .central {display: none;}
}

.left {
    float: left;
    width: 20%;
    min-width: 250px;
    height: 100%;
    padding-left: 20px;
    z-index: 100;
}
.left > img {
    width: 100%;
    min-width: 200px;
    max-width: 210px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    z-index: 100;
}

.right {
    float: right;
    text-align: right;
    height: 100%;
    vertical-align: middle;
}

.rightWrapper {
    width: 100%;
    position: absolute;
    right: 2%;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    z-index: 20;
}
