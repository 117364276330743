.dialog {
    border-radius: 10px;
}

.content {
    font-size: 20px;
    border: 1px solid gray;
    border-radius: 10px;
}

.body {
    max-height: 80vh;
    overflow: scroll;
}

.danger {
    background-color: red;
    color: white;
}

.warning {
    background-color: orange;
    color: white;
}
.info {
    background-color: lightgrey;
    color: black;
}
.success {
    background-color: lightgreen;
    color: black;
}