.notificationWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  height: 80px;
  pointer-events: none;
}

.limitSize {
  width: 20vw;
  max-width: 250px;
}

.moreNotes {
  pointer-events: auto;
  cursor: pointer;
}

@keyframes fadeInUp {
  from {
      transform: translate3d(0,20px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,20px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 1
}

.fadeInUp {
  opacity: 1;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}
