.wrapper {
    font-size: 30px;
}

.wrapper i {
    margin: 10px;
}

.viewButtonWrapper {
    background-color: #F4F4F4;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    margin-right: 10px;
}

.downloadButton {
    cursor: pointer;
    color: #707070;
    font-size: 20px;
    border: 1px solid #707070;
    background-color: transparent;
    border-radius: 4px;
    text-align: center;
    font-weight: 700;
    float: right;
}

.downloadButton:hover {
    color: black;
    border-color: black;
}

.datePicker {
    border: none;
    margin-left: 10px;
    color: blue;
    background-color: transparent;
}

.modalButton {
    cursor: pointer;
}

@media screen and (min-width: 1100px){
    .notModalButton {
        display: none;
    }
}