.dummyImage {
    height: 250px;
}

.speedoGroup{
    white-space: nowrap;
    width: 100%;
    overflow-x: scroll;
}

.speedoGroup > Speedo{
    display: inline-block;
}

.wrapper {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}