* {
    --bar-width: 80px;
    --bar-height: 80%;
    --bar-min-height: 200px;
    --standard-color: #BEC8D1;
    --warning-color: #EDAC2C;
    --danger-color: #E23922;
    --success-color: green;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px 15px 20px;
    height: 34vh;
    min-height: 300px;
    width: 200px;
    position: relative;
  }
  
  .container[data-mode=info] .bar {
    border-color: var(--standard-color);
  }
  .container[data-mode=warning] .bar {
    border-color: var(--warning-color);
  }
  .container[data-mode=danger] .bar {
    border-color: var(--danger-color);
  }
  
  .bar {
    position: relative;
    width: var(--bar-width);
    height: var(--bar-height);
    border-radius: calc(2 * var(--bar-width) /2);
    border: 5px solid var(--standard-color);
    z-index: 10;
  }

  
  .bar > div, .mask > div, .mask, .barDisplay {
    position: absolute;
    width: 100%;
    left: 0;
}

.bar > span {
    position: absolute;
    left: 80px;
    background-color: white;
    line-height: 1;
    padding-right: 4px;
}
.barDisplay {
    background-color: var(--standard-color);
    height: 100%;
}

.yellowRange {
    height: 100%;
    animation: all ease-in-out 0.4s;
    z-index: 2;
    background-color: var(--warning-color);
}
.greenLine {
    height: 4px;
    z-index: 2;
    animation: all ease-in-out 0.4s;
    background-color: var(--success-color);
}
.redRange {
    height: 100%;
    animation: all ease-in-out 0.4s;
    z-index: 3;
    background-color: var(--danger-color);
}
.mask {
    height: calc(100%);
    z-index: 7;
    border: 5px solid white;
    border-radius: calc(var(--bar-width) /2);
    overflow: hidden;
}
.pointer {
    background-color: #707070;
    width: calc(var(--bar-width) - 10px);
    height: 4px;
    z-index: 8;
    border-radius: 4px;
}

  .card {
    margin-top: 10px;
    border-radius: 4px;
    width: calc(var(--bar-width) * 2);
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
    border: 2px solid;
    background-color: #F7F7F7;
  }
  
  .card .title {
    font-size: 22px;
  }

  .card .info {
    font-size: 14px;
    font-style: italic;
  }
  
  .container[data-mode=info] .card {
    border-color: transparent;
  }
  .container[data-mode=warning] .card {
    border-color: var(--warning-color);
  }
  .container[data-mode=danger] .card {
    border-color: var(--danger-color);
  }

  .name {
    position: absolute;
    font-size: 26px;
    right: 160px;
    color: #707070;
    max-width: 60px;
    word-wrap: break-word;
    white-space: normal;
    text-align: right;
    overflow: wrap;
    line-height: 1;
    font-weight: 200;
  }

  .sensorInfo {
    color: #707070;
    font-size: 22px;
  }

  .infoValue {
    color: blue;
  }

  .sensorInfo > tbody > tr > td:first-child {
    text-align: left;
  }
  .sensorInfo > tbody > tr > td:last-child {
    text-align: right;
  }

  .sensorInfo > tbody > tr > td {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  .sensorInfo > tbody > tr > td:first-child {
    width: 30%;
  }