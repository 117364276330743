.wrapper {
    overflow-y: scroll;
    height: 90%;
    /* padding-top: 70px; */
}

.viewHeader{
    position: relative;
    padding-bottom: 10px;
    width: 100%;
    background-color: white;
    z-index: 1;
}