.timeSelector {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.timeSelector input[type="radio"] {
    display: none;
}

.timeSelector label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    color: #707070;
    font-size: 20px;
    border: 1px solid #707070;
    border-radius: 4px;
    min-width: 100px;
    text-align: center;
    margin: 1vh 2% 0 0;
    font-weight: 500;
}

.timeSelector input[type="radio"]:hover+label {
    color: black;
    border-color: black;
}

.timeSelector input[type="radio"]:checked+label {
    color: blue;
    border-color: blue;
}

.sensorSelector {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.sensorSelector input[type="checkbox"] {
    display: none;
}

.sensorSelector label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    color: #707070;
    font-size: 20px;
    border: 1px solid #707070;
    border-radius: 4px;
    padding: 0px 5px 0px 5px;
    min-width: 100px;
    text-align: center;
    margin: 0 1% 2vh 1%;
    font-weight: 500;
}

.sensorSelector input[type="checkbox"]:hover+label {
    color: black;
    border-color: black;
}

.sensorSelector input[type="checkbox"]:checked+label {
    color: blue;
    border-color: blue;
}


