  .split-pane-row {
    padding-top: 70px;
    min-width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
  }
  
  .separator-col {
    border: 2px solid #E9ECEF;
    cursor: col-resize;
  }

  h1 {
    color: #707070;
  }

  .noSelect {
    -webkit-user-select: none;  
    -moz-user-select: none; 
    -ms-user-select: none;  
    user-select: none; 
  }